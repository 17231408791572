import React from "react";
import Layout from "../../../../components/Layout/layout";
import BlogDetailPage from "../../../../components/Blog-Detail/index";
import SEO from "../../../../components/SEO/seo";
const BlogDetail = () => {
	return (
		<Layout>
			<SEO
				title="Travel Easy And Always Be On Time With Pre-booking Taxi"
				meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
				description="It's always a better idea to book your taxi in advance by using the pre booking taxi service rather than at the last minute."
			/>
			<BlogDetailPage />
		</Layout>
	);
};

export default BlogDetail;
